<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #lotterySettings="item">
              <lottery-setting-form
                :field="item.field"
                v-model="data.lotterySettings"
              />
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    :reduce="true"
                    v-model="data[item.field.key]"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    @optionChange="changeOption"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :readonly="true"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #username="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <input
                    type="hidden"
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                  />
                  <b-row>
                    <n-single-select
                      v-model="username.first"
                      :options="alphabet"
                      :clearable="false"
                      :loading="checkUsernameLoading"
                      class="col-6"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :readonly="true"
                    ></n-single-select>
                    <n-single-select
                      v-model="username.second"
                      :options="alphabet"
                      :clearable="true"
                      :loading="checkUsernameLoading"
                      class="col-6"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :readonly="true"
                    ></n-single-select>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                v-if="$can('update', 'agent')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import Alphabet from "@/data/numberAlphabet";
import Timeout from "@/data/timeout";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NSingleSelect from "@/components/NSingleSelect";
import BetSettingMenu from "./BetSettingMenu.vue";
import LotterySettingForm from "./LotterySettingForm";

const AgentRepository = Repository.get("agent");
const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NSingleSelect,
    BetSettingMenu,
    LotterySettingForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        lastName: null,
        phone: null,
        username: null,
        password: null,
        commissionNumTwo: 0,
        commissionNumThree: 0,
        share: 0,
        ccy: null,
        lotteryIds: [],
        lotterySettings: [],
        isEnable: true,
        isReadonly: false,
        maxWinDay: 0,
        maxTodayBet: 0,
        minBetInvoice: 0,
        maxBetInvoice: 0,
        minBetGame: 0,
        maxBetGame: 0,
        minBetPerNumTwo: 0,
        maxBetPerNumTwo: 0,
        minBetPerNumThree: 0,
        maxBetPerNumThree: 0,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
        first: null,
        second: null,
      },
    };
  },
  watch: {
    "username.first": function (value) {
      this.concatUsername();
    },
    "username.second": function (value) {
      this.concatUsername();
    },
    "username.parent": function (value) {
      this.concatUsername();
    },
  },
  computed: {
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      let userData = JSON.parse(localStorage.getItem("userData"));
      return userData?.typeId;
    },
  },
  mounted() {
    this.show();
    if (this.typeId != 1) {
      const index = this.fields.findIndex(
        (item) => item.key === "isCashMarket"
      );
      if (index != -1) {
        this.fields.splice(index, 1);
      }
    }
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          data.forEach((element) => {
            this.data.lotterySettings.push({
              lotteryName: element.name,
              lotteryNameKh: element.nameKh,
              lotteryId: element.id,
              commissionTypeThree: 0,
              commissionTypeTwo: 0,
              commissionNumTwo: null,
              commissionNumThree: null,
            });
          });
        }
      });
    },
    changeOption(value) {
      this.username.parent = value?.username;
    },
    show() {
      AgentRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          let username = data.username;
          username = username.replace(data.parentUsername, "");
          this.username.first = username.charAt(0);
          this.username.second = username.charAt(1);
          this.initData = {
            ...data,
            lastName: data.firstName
              ? `${data.firstName} ${data.lastName}`
              : data.lastName,
            firstName: null,
          };

          if (!data.lotterySettings.length) {
            this.getLottery();
          }
        }
      });
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          AgentRepository.update(this.$route.params.id, this.data)
            .then((response) => {
              this.$router.push({
                name: "view-agent",
                params: { id: response.data.data.id },
              });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    concatUsername() {
      if (this.username.first && this.username.parent) {
        this.data.username = `${this.username.parent}${this.username.first}${
          this.username.second ? this.username.second : ""
        }`;
      }
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
      } else if (value.key === "givenCredit") {
        value.disabled = true;
      }

      return value;
    });

    const alphabet = [...Alphabet];
    const timeout = [...Timeout];
    return { fields, alphabet, timeout };
  },
};
</script>
